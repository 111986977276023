<template>
  <div id="main">
    <div class="left">
      <el-menu style="height: 100%;width: 300px;" :router="true" :default-active="$route.path">
        <el-menu-item index="/cardManager">
          <i class="el-icon-menu"></i>
          <span slot="title">卡密管理</span>
        </el-menu-item>
        <el-menu-item index="/hostManager">
          <i class="el-icon-setting"></i>
          <span slot="title">域名管理</span>
        </el-menu-item>
        <el-menu-item index="/verifyLog">
          <i class="el-icon-setting"></i>
          <span slot="title">WS验证记录</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminMain"
}
</script>

<style scoped>
#main {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.left {
  width: 300px;
  height: 100%;
}
.right{
  flex: 1;
  height: 100%;
}
</style>